.footer {
    width: 100%;
    text-align: end;
    padding: 35px 0 0px;
    font-size: 14px;
    color: #d1d1d1;
    font-family: "Integral";
    letter-spacing: 1px;

    @media screen and (max-width: 1070px) {
        font-size: 11px;
        padding: 5px 0 0px;

        &__text {
            margin-right: 20px;
        }
    }

    @media screen and (max-width: 480px) {
        font-size: 9px;
    }

}