@font-face {
  font-family: "Integral";
  src: local("Integral"),
    url(../../UI/Fonts/IntegralCF-Bold.woff) format("woff");
}

.presentation {
  color: #d1d1d1;
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: space-between;
  padding: 50px 20px;

  &__wrapper {
    display: flex;
    align-items: flex-end;
  }




  &__box-experience {
    padding-left: 25px;
  }

  &__box-welcome {
    display: flex;
    flex-direction: row;
    align-items: flex-end;


  }

  &__animation {
    position: relative;
    padding-left: 50px;
    display: flex;
    justify-content: center;

    &:hover .presentation__hover-text {
      opacity: 1;
    }

  }

  &__hover-text {
    position: absolute;
    bottom: 150%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-family: "Integral";
    background-color: #f1f1f1;
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    font-size: 10px;
  }

  &__img {
    width: 100px;
    height: auto;
    border-radius: 10%;
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.5);
    }
  }



  &__name {
    padding-left: 30px;
    margin: 0;
    font-size: 35px;
    color: rgba(89, 40, 229);
    font-family: "Integral";
  }

  &__description-wrapper {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 300px;
    overflow: auto;
  }


  &__description {
    color: #d1d1d1;
    line-height: 25px;
    padding: 0px 50px;
    padding-top: 15px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 200px;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      background-color: rgba(206, 160, 160);
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;


  }

  &__sidebar-list-wrapper {
    border-radius: 10px;
    overflow: hidden;
    position: relative;

  }

  &__sidebar-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 320px;
    padding: 30px 30px 0;



    &::before {
      content: '';
      position: absolute;
      height: 5px;
      width: 75px;
      top: 0;
      left: 0;
      background-color: rgba(206, 160, 160);
    }


    &::after {
      content: '';
      position: absolute;
      width: 5px;
      height: 150px;
      top: 0;
      left: 0;
      background-color: rgba(206, 160, 160);
      ;
    }
  }

  &__sidebar-title {
    font-family: "Integral";
    font-size: 30px;
    color: #d1d1d1;
  }

  &__project {
    font-size: 15px;
    text-decoration: none;
    border-bottom: 1px solid rgba(89, 40, 229);
    display: inline-block;
    padding-bottom: 5px;
    cursor: pointer;
  }






  @media screen and (max-width: 1400px) {
    padding: 100px 20px;
  }

  @media screen and (max-width: 1090px) {
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__box-experience {
      padding-left: 0;
    }

    &__sidebar {
      width: 100%;

    }

    &__sidebar-list {
      padding-left: 50px;



    }

    &__project {
      font-size: 13px;
      border-bottom: 2px solid rgba(89, 40, 229);

    }

    &__name {
      font-size: 26px;
    }

    &__img {
      width: 80px;

    }

    &__description {
      line-height: 20px;
      font-size: 13px;
      padding: 0px 0px;

      &::after {
        display: none;
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding: 0px 50px;
    &__description-wrapper {
     
      height: 240px;
 

    }
&__img{
  display:none
}
    &__sidebar-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-width: 100%;

    }

    &__box-welcome {
      flex-direction: row-reverse;
      align-items: flex-end;
      justify-content: space-between;
    }

    &__animation {
      padding-left: 0;

    }

    &__img {
      width: 50px;


      &:hover {
        transform: scale(1.3);
      }
    }

    &__name {
      font-size: 20px;
      padding-left: 0px;
    }



    &__description {
      line-height: 15px;
      padding: 0px 0px;
      text-align: justify;
      font-size: 12px;

      &::after {
        display: none;
      }
    }

    &__sidebar-list {
      padding: 0px 0px;


      &::before {
        display: none;
      }


      &::after {
        display: none;

      }
    }

    &__sidebar-title {
      font-size: 20px;
      margin-bottom: 0;
      color: rgba(89, 40, 229)
    }

    &__project {
      margin-bottom: 0px;
    }

    &__hover-text {
      bottom: 150%;
      left: 40%;
      font-size: 8px;
      letter-spacing: 1px;
    }
  }





}