.blog {
    display: flex;
    align-items: flex-end;

    &__text {
        margin-bottom: 0;
    }
    &__strong {
        color: rgba(206, 160, 160);
    }
}