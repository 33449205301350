.welcome {

    &__link {


        font-weight: bold;
        color: rgba(206, 160, 160);
        text-decoration: none;


        &:hover,
        &:active,
        &:focus {
            text-decoration: 1px solid underline rgba(206, 160, 160);
            color: rgba(206, 160, 160);
        }
    }
}