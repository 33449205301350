.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #d1d1d1;
  font-family: "Montserrat", sans-serif;

  &__details {
    display: flex;
    align-items: center;
  }

  &__text {
    margin-left: 3px;
    margin-right: 10px;
    font-size: 13px;

    &:hover {
      color: white;
    }
  }

  &__img {
    width: 70px;
    height: auto;
    border-radius: 10%;
    cursor: pointer;
    transition: transform 1s ease-in-out;

    &:hover {
      transform: rotate(360deg);
    }
  }

  &__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px;
    padding: 0px;
    list-style: none;

  }

  &__logo {
    width: 25px;
    height: auto;

  }

  &__link {
    margin-right: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;


    &:hover {
      transform: scale(1.3);
    }
  }

  @media screen and (max-width: 1070px) {
    &__img {
      width: 70px;
      margin-left: 20px;
    }

    &__text {
      font-size: 10px;
    }
  }

  @media screen and (max-width: 480px) {
    display: flex;
    justify-content: space-around;

    &__logo {
      width: 15px;
    }


    &__text {
      font-size: 9px;
    }

    &__img {
      width: 50px;
      margin-left: 20px;

    }
  }

}