.stacks {
    display: flex;
    flex-direction: column;
    align-items: flex-end;



    &__strong {
        color: rgba(206, 160, 160);
        padding: 5px;
    }

    &__bracket {
        font-size: 25px;
        font-weight: bold;
        color: rgba(89, 40, 229);
  
    }

    @media screen and (max-width: 480px) {

        &__strong {
            padding: 0px;
        }

        &__bracket {
            font-size: 15px;
            font-weight: bold;
            color: rgba(89, 40, 229);


        }
    }
}